import React, { useState, useRef, useEffect } from 'react'
import { makeStyles, styled } from '@material-ui/styles'
import Fab from "@material-ui/core/Fab"
import Pagination from "@material-ui/lab/Pagination"
import PaginationItem from "@material-ui/lab/PaginationItem"



import Layout from '../components/ui/layout'
import { Grid } from '@material-ui/core'

import FilterCard from '../components/annonce-list/filter-card/filter-card'
import ListOfAnnonces from '../components/annonce-list/list-of-annonces'

const useStyles = makeStyles(theme => ({
    mainContainer: {
        padding: '0rem 2rem 0rem 2rem',
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },

    mainCard: {
        padding: "2rem 2rem",
    },
    cardMedia: {
        height: '16rem',
        width: '20rem'
    },
    resultButton: {
        position: "absolute",
        bottom: 0,
        left: "45%",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.light
        }
    },
    filterContainer: {
        marginTop: "5rem",
        width: '100%'
    },
    fab: {
        paddingTop: "0.5rem",
        alignSelf: "flex-end",
        marginRight: "1rem",
        marginBottom: "1rem",
        color: "#fff",
        fontFamily: "Montserrat",
        fontSize: "3rem",
        width: "3rem",
        height: "3rem",
    },
    pagination: {
        alignSelf: "flex-end",
        //marginRight: "2%",
        //marginTop: "-3rem",
        marginBottom: "1rem",
        [theme.breakpoints.only("md")]: {
            marginTop: "1rem",
        },
    },
    popoverContainer: {
        padding: "1rem 2rem",
        width: "15rem",
        height: "15rem"
    },
    prixPopoverContainer: {
        width: "38rem",
        height: "13rem",
        padding: "1rem 2rem",
        [theme.breakpoints.down("xs")]: {
            height: "16rem"
        }
    },
    popoverPriceRow: {
        width: "34rem",
        [theme.breakpoints.down('xs')]: {
            width: "40rem",
            height: "8rem"
        }
    },
    pricePopoverButtonRow: {
        width: "32rem",
        [theme.breakpoints.down('xs')]: {
            width: "22rem"
        }
    },
    prixSurfaceContainer: {
        width: "40rem",
        height: "13rem",
        padding: "1rem 2rem"
    },
    terrainPopoverContainer: {
        width: "40rem",
        height: "13rem",
        padding: "1rem 2rem"
    },
    piecePopoverContainer: {
        width: "38rem",
        padding: "1rem 2rem",
        height: "12rem"
    },
    alerteButton: {
        position: "absolute",
        right: "0.5rem",
        bottom: "0.5rem",
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        },
        [theme.breakpoints.down("xs")]: {
            width: "22rem",
            left: "2%",
            border: `2px solid ${theme.palette.secondary.main}`,
        },
        [theme.breakpoints.only("md")]: {
            marginTop: "1rem",
            border: "none"
        },
        [theme.breakpoints.only("sm")]: {
            bottom: "3rem"
        }
    },

}))



export const StyledPagination = props => {
    const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
        fontFamily: "Montserrat",
        fontSize: "1rem",
        color: theme.palette.primary.main,
        "&.Mui-selected": {
            color: "#fff",
        },
    }))

    return (
        <Pagination
            {...props}
            renderItem={item => <StyledPaginationItem {...item} />}
        />
    )
}

const ListAnnonces = ({ pageContext }) => {
    const classes = useStyles()
    const [page, setPage] = useState(1)
    const [layout, setLayout] = useState("grid")
    const scrollRef = useRef(null)
    const [annonces, setAnnonces] = useState(pageContext.annonces)

    useEffect(() => {
        setPage(1)
    }, [layout])


    let content = [];
    content = pageContext.annonces


    const scroll = () => {
        scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }

    const annoncesPerPage = (layout === "grid") ? 16 : 3

    const numPages = Math.ceil(content.length / annoncesPerPage)

    // const matchesXS = useMediaQuery(theme => theme.breakpoints.down("xs"))

    return (
        <Layout>
            <Grid container alignItems='center' direction='column' classes={{ root: classes.mainContainer }}>
                <div ref={scrollRef} />
                <Grid classes={{ root: classes.filterContainer }} item>
                    <FilterCard
                        listeAnnonces={pageContext.annonces}
                        listeCommunes={pageContext.listCommunes}
                        setAnnonces={setAnnonces}/>
                </Grid>
                <Grid item classes={{ root: classes.annoncesContainer }} container spacing={2}>
                    
                        <ListOfAnnonces annonces={annonces}
                        setPage={setPage}
                            layout={layout}
                            setLayout={setLayout}
                            content={annonces}
                            page={page} annoncesPerPage={annoncesPerPage} />
                    
                </Grid>
                <Grid item>
                    <StyledPagination
                        count={numPages}
                        page={page}
                        onChange={(e, newPage) => setPage(newPage)}
                        color="primary"
                        classes={{ root: classes.pagination }}
                    />
                </Grid>
                <Fab onClick={scroll} color="primary" classes={{ root: classes.fab }}>
                    ^
                </Fab>
            </Grid>
        </Layout >
    )
}
export default ListAnnonces