import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NotificationAddIcon from "@material-ui/icons/NotificationImportant"
import { makeStyles } from "@material-ui/styles"
import { Link } from 'gatsby'
import React, { useState } from 'react'
import theme from "../../ui/theme"
import { fromOptionLabel } from '../filter/FilterFunctionFactory'
import { updateFilter } from './filter-services'
import { ItemsSelectionForm } from './item-selection-form'
import { ItemSelectionMinMaxForm } from './item-selection-min-max-form'


const useStyles = makeStyles(theme => ({

    resultButton: {
        position: "absolute",
        bottom: 0,
        left: "40%",
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        },
        [theme.breakpoints.down("xs")]: {
            left: "2%",
            bottom: "4rem",
            width: "22rem",
            border: `2px solid ${theme.palette.secondary.main}`,
        }
    },
    filterCard: {
        padding: "2rem 0rem 3rem 0rem",
        width: "100%",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            height: "19rem",
            padding: "1rem"
        }
    },
    formsRow: {
        width: '100%'
    },

    filterButton: {
        borderRadius: "5rem",
        border: `2px solid ${theme.palette.common.clair}`,
        marginTop: "1rem"
    },
    prixPopoverContainer: {
        width: "38rem",
        height: "13rem",
        padding: "1rem 2rem",
        [theme.breakpoints.down("xs")]: {
            height: "16rem"
        }
    },
    popoverTextField: {
        width: "15rem"
    },
    popoverPriceRow: {
        width: "34rem",
        [theme.breakpoints.down('xs')]: {
            width: "40rem",
            height: "8rem"
        }
    },
    pricePopoverButtonRow: {
        width: "32rem",
        [theme.breakpoints.down('xs')]: {
            width: "22rem"
        }
    },
    alerteButton: {
        position: "absolute",
        right: "0.5rem",
        bottom: "0.5rem",
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        },
        [theme.breakpoints.down("xs")]: {
            width: "22rem",
            left: "2%",
            border: `2px solid ${theme.palette.secondary.main}`,
        },
        [theme.breakpoints.only("md")]: {
            marginTop: "1rem",
            border: "none"
        },
        [theme.breakpoints.only("sm")]: {
            bottom: "3rem"
        }
    }
}))

const defautSearchCriteria = {
    villes: [],
    prix: { minimum: 0, maximum: 10000000 },
    surface: { minimum: 0, maximum: 500 },
    piece: { minimum: 0, maximum: 20 },
    typesDeBien: []
}
/*
const listeVilles = [
    { label: "Nantes", value: "nantes" },
    { label: "Nantes (44000)", value: "nantes44000" },
    { label: "Nantes (44100)", value: "nantes44100" },
    { label: "Nantes (44200)", value: "nantes44200" }
]*/
const listeTypeBien = [
    { label: "Maison", value: "Maison" },
    { label: "Appartement", value: "Appartement" }];

export default function FilterCard({ listeAnnonces, listeCommunes, setAnnonces }) {

    const classes = useStyles()
    const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'))

    const [searchCriteria, setSearchCriteria] = useState(defautSearchCriteria)
    const [searchCount, setSearchCount] = useState(listeAnnonces.length)

    // Dialog start
    const [open, setOpen] = useState(false);
    // const [scroll, setScroll] = useState('paper');

    const [chambreMin, setChambreMin] = useState(1)

    const handleChambreMin = (e, newVal) => {
        setChambreMin(newVal)
    }

    //Dialog end

    const handleClickOpen = () => {
        setOpen(true);
        // setScroll();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validateSelectionForms = (e, option, i) => {
        updateFilter(i, options);
        option.onClose(e);
    }

    // const [filterOptions, setFilterOptions] = useState(options)

    let options = [
        {
            key: "ville",
            label: 'ville',
            type: 'selectedOptions',
            valide: searchCriteria.villes.length > 0,
            villes: searchCriteria.villes,
            onClick: (e) => { }
        },
        {
            key: "typesDeBien",
            label: 'typeDeBien',
            type: 'selectedOptions',
            typesDeBien: searchCriteria.typesDeBien,
            valide: searchCriteria.typesDeBien.length > 0,
            onClick: (e) => { }
        },
        {
            key: "prix",
            label: "prix",
            popoverText: "Prix",
            popoverId: "id_popover_prix",
            values: searchCriteria.prix,
            valide: true,
            minimumHelperText: "Entez un montant correct",
            inputProps: {
                endAdornment: (
                    <InputAdornment position='end'>
                        €
                    </InputAdornment>)
            },
            minimumPlaceHolder: "Prix minimum",

            maximumHelperText: "Entez un montant correct svp",
            maximumPlaceHolder: "Prix maximum",
            onValidate: (itemValue) => setSearchCriteria({ ...searchCriteria, prix: itemValue }),
            onClear: () => setSearchCriteria({ ...searchCriteria, prix: { minimum: 0, maximum: 10000000 } })
        },
        {
            key: "surface",
            label: "Surface habitable",
            popoverText: "Surface habitable",
            popoverId: "id_popover_surface_habitable",
            values: searchCriteria.surface,
            valide: true,
            minimumHelperText: "Entrez une valeur minimale",
            inputProps: {
                endAdornment: (
                    <InputAdornment position='end'> m2 </InputAdornment>)
            },
            minimumPlaceHolder: "Surface minimum",
            maximumHelperText: "Entrez une valeur maximale",
            maximumPlaceHolder: "Surface maximale",
            onValidate: (itemValue) => setSearchCriteria({ ...searchCriteria, surface: itemValue }),
            onClear: () => setSearchCriteria({ ...searchCriteria, surface: { minimum: 0, maximum: 500 } })
        },
        {
            key: "piece",
            label: "piece",
            popoverText: "piece",
            popoverId: "id_popover_piece",
            values: searchCriteria.piece,
            valide: true,
            minimumHelperText: "Entrez une valeur minimale",
            inputProps: undefined,
            minimumPlaceHolder: "Nombre de pièce minimum",
            maximumHelperText: "Entrez une valeur maximale",
            maximumPlaceHolder: "Nombre de pièce maximale",
            onValidate: (itemValue) => setSearchCriteria({ ...searchCriteria, piece: itemValue }),
            onClear: () => setSearchCriteria({ ...searchCriteria, piece: { minimum: 0, maximum: 50 } })
        }
    ]

    const applyFilters = () => {
        // var isFiltered = options.filter(option => option.valide).length > 0;
        let filteredAnonces = listeAnnonces;
        options.filter(option => option.valide)
            .forEach(option => {
                let annonceFilter = fromOptionLabel(option);
                filteredAnonces = annonceFilter ? annonceFilter.filter(filteredAnonces) : filteredAnonces;
            })
        if (filteredAnonces !== null) {
            setSearchCount(filteredAnonces.length)
            setAnnonces(filteredAnonces)
        }
    }

    const communes = listeCommunes.map(item => {
        return  { label: item, value: item }
    }
        );
    return (
        <Card classes={{ root: classes.filterCard }}>
            <Grid container alignItems='center' justifyContent='center' classes={{ root: classes.formsRow }}
                direction='row' spacing={3}>

                <ItemsSelectionForm
                    buttonLabel={"Communes"}
                    popoverId={"communes_popower"}
                    initialSelection={searchCriteria.villes}
                    itemsList={communes}
                    onValidate={(selectedItems) => setSearchCriteria({ ...searchCriteria, villes: selectedItems })}
                    onClear={() => setSearchCriteria({ ...searchCriteria, villes: [] })} />

                <ItemsSelectionForm
                    buttonLabel={"Type de bien"}
                    popoverId={"typeDeBien_popower"}
                    initialSelection={searchCriteria.typesDeBien}
                    itemsList={listeTypeBien}
                    onValidate={(selectedItems) => setSearchCriteria({ ...searchCriteria, typesDeBien: selectedItems })}
                    onClear={() => setSearchCriteria({ ...searchCriteria, typesDeBien: [] })} />

                {options.filter(option => option.type !== 'selectedOptions')
                    .map(option => (
                        <ItemSelectionMinMaxForm
                            key={option.key}
                            buttonLabel={option.label}
                            popoverText={option.popoverText}
                            popoverId={option.popoverId}
                            initialItemValue={option.values}
                            minimumHelperText={option.minimumHelperText}
                            maximumHelperText={option.maximumHelperText}
                            minimumPlaceHolder={option.minimumPlaceHolder}
                            maximumPlaceHolder={option.maximumPlaceHolder}
                            inputProps={option.inputProps}
                            onValidate={option.onValidate}
                            onClear={option.onClear}
                        />
                    ))
                }
            </Grid>

            <Button classes={{ root: classes.resultButton }} onClick={(e) => applyFilters()}>
                <Typography style={{ textTransform: "none", color: theme.palette.common.white }}>
                    Rechercher ({searchCount} résultat{searchCount > 1 && "s"})
                </Typography>
            </Button>
            <Button component={Link} to="/alerte" classes={{ root: classes.alerteButton }}>
                <NotificationAddIcon style={{ fill: theme.palette.secondary.main }} />
                <Typography
                    style={{ color: theme.palette.secondary.main, textTransform: "none" }}>
                    Créer une alerte
                </Typography>
            </Button>
        </Card>
    )
}