//Filtre generique
export function AnnonceFilter(option) {
    this.option = option;
}

AnnonceFilter.prototype.filter = function () {
};

//ville
export function Ville(option) {
    this.option = option;
}
Ville.prototype = Object.create(AnnonceFilter.prototype);
Ville.prototype.filter = function (toFilter) {
    return toFilter.filter(annonce => this.option.villes.includes(annonce.city))
};

//Type de bien
export function TypeDeBien(option) {
    this.option = option;
}
TypeDeBien.prototype = Object.create(AnnonceFilter.prototype);
TypeDeBien.prototype.filter = function (toFilter) {
    return toFilter.filter(annonce => this.option.typesDeBien.includes(annonce.type))
};

// prix
export function Prix(option) {
    this.option = option;
}
Prix.prototype = Object.create(AnnonceFilter.prototype);
Prix.prototype.filter = function (toFilter) {
    console.log(this.option)
    return toFilter.filter(annonce => annonce.price >= parseInt(this.option.values.minimum) &&
        annonce.price <= parseInt(this.option.values.maximum))
};


// Surface
export function SurfaceHabitable(option) {
    this.option = option;
}
SurfaceHabitable.prototype = Object.create(AnnonceFilter.prototype);
SurfaceHabitable.prototype.filter = function (toFilter) {
    return toFilter.filter(annonce => annonce.surfaceHabitable >= parseInt(this.option.values.minimum) &&
        annonce.surfaceHabitable <= parseInt(this.option.values.maximum));
};

// NombrePieces
export function Pieces(option) {
    this.option = option;
}
Pieces.prototype = Object.create(AnnonceFilter.prototype);
Pieces.prototype.filter = function (toFilter) {
    return toFilter.filter(annonce => annonce.piece >= parseInt(this.option.values.minimum) &&
        annonce.piece <= parseInt(this.option.values.maximum));
};