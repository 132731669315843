import { AnnonceFilter, Ville, Prix, SurfaceHabitable, Pieces, TypeDeBien } from './FilterFunctions.js'


export const fromOptionLabel = (option) => {
    switch (option.label) {
        case 'ville':
            return new Ville(option);
        case 'prix':
            return new Prix(option);
        case 'pièces':
            return new Pieces(option);
        case 'Surface habitable':
            return new SurfaceHabitable(option);
        case 'typeDeBien':
            return new TypeDeBien(option);
        default:
            break
    }
}