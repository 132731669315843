import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Link, navigate } from 'gatsby'
import { Card, Grid, Typography } from '@material-ui/core'
import Button from "@material-ui/core/Button"
import useMediaQuery from '@material-ui/core/useMediaQuery'



const useStyles = makeStyles(theme => ({

    imageContainer: {
        marginBottom: "3rem",
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '20rem',
        height: '13rem',
        position: 'relative',
        [theme.breakpoints.down("md")]: {
            height: '12rem',
            width: '16rem',
        },
        [theme.breakpoints.down("xs")]: {
            height: '5rem',
            width: '7rem',
            marginBottom: "0.5rem",
        },
    },

    desContainer: {
        width: '20rem'
    },
    imgg: {
        width: '18rem',
        height: '15rem',
        [theme.breakpoints.down("md")]: {
            height: '12rem',
            width: '14rem',
        },
        [theme.breakpoints.down("xs")]: {
            height: '5rem',
            width: '6rem',
        },
    },
    itemCard: {
        // padding: "2rem 2rem",
        width: '100%',
        marginBottom: '1rem',
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        },
        [theme.breakpoints.down("xs")]: {
            padding: '0.5rem 0',
            marginBottom: '0.5rem'
        }
    },
    resultButton: {
        position: "absolute",
        bottom: 0,
        left: "45%",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.light
        }
    },
    infoRow: {
        width: "10rem",
        height: "14rem",
        marginLeft: '1rem',
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
            height: '5rem',
            width: '17.2rem'
        }
    }
}))

const AnnonceFrameList = (
    {
        annonce,
    }) => {
    const classes = useStyles()
    const theme = useTheme()

    const matchesXS = useMediaQuery(theme => theme.breakpoints.down("xs"))

    return (
        <Card elevation={4} classes={{ root: classes.itemCard }}>
            <Grid component={Button} onClick={() => navigate(`../maison/${annonce.id.toLowerCase()}`)} style={{padding: "2rem 2rem"}} item container >
                <Grid item container alignItems='flex-start' alignContent='flex-start' direction='row'>
                    <Grid md={3} item classes={{ root: classes.imageContainer }}>
                        <img md={3} className={classes.imgg} src={annonce.img} alt="image du bien" />
                    </Grid>
                    <Grid md={8} item container alignItems='flex-start' justifyContent='space-between' classes={{ root: classes.infoRow }} direction='column'>
                        <Grid item>
                            <Typography variant='h1' noWrap
                                style={{ color: theme.palette.common.black, textTransform: "none" }}>
                                {annonce.title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography noWrap
                                variant='body1'
                                style={{ color: theme.palette.common.black, wordWrap: "break-word", textTransform: "none" }}>
                                {annonce.desc}
                            </Typography>
                        </Grid>
                        {!matchesXS && (<Grid item>
                            <Typography noWrap
                                variant='body1'
                                style={{ color: theme.palette.common.black, textTransform: "none" }}>{annonce.city}
                                {/* {annonce.zipCode} */}
                            </Typography>
                        </Grid>)}
                        <Grid item>
                            <Typography
                                style={{ color: theme.palette.secondary.main, fontWeight: "bold" , textTransform: "none" }}>{annonce.price} €
                            </Typography>
                        </Grid>
                        {!matchesXS && (<Grid item>
                            <Typography style={{ textTransform: "none" }} component={Link} to={`../maison/${annonce.id.toLowerCase()}`}>Plus de details</Typography>
                        </Grid>)}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}
export default AnnonceFrameList