import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Popover from '@material-ui/core/Popover'
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"
import React, { useState } from 'react'
import theme from "../../ui/theme"
import { FilterSelectItem } from '../filter/FilterSelectItem'

const useStyles = makeStyles(theme => ({

    formsRow: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    filterButton: {
        borderRadius: "5rem",
        border: `2px solid ${theme.palette.common.clair}`,
        marginTop: "1rem"
    },
    popoverContainer: {
        padding: "1rem 2rem",
        width: "30rem",
        height: "25rem"
    },

    popoverTextField: {
        width: "15rem"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))


export const ItemsSelectionForm = ({ buttonLabel, popoverId, itemsList, initialSelection, onValidate, onClear }) => {

    const classes = useStyles()


    const [formAnchor, setFormAnchor] = useState(null)
    const [popoverAnchor, setAnchorPopover] = useState(null)
    const [selectedItems, setSelectedItems] = useState([])

    const onPopoverOpen = (e) => {
        setSelectedItems(initialSelection)
        setAnchorPopover(e.currentTarget)
    }
    const onPopoverFormClear = (e) => {
        onClear()
        setAnchorPopover(null)
    }

    const onPopoverFormValidate = (e) => {
        onValidate(selectedItems)
        setAnchorPopover(null)
    }

    const onValueChanged = (event) => {
        const { target: { value } } = event;
        const villes = typeof value === 'string' ? value.split(',') : value
        setSelectedItems(villes)
    }

    return <Grid item>
        <Button onClick={onPopoverOpen} aria-describedby={popoverId} classes={{ root: classes.filterButton }}>
            {buttonLabel}
        </Button>
        <Popover
            id={popoverId}
            anchorEl={popoverAnchor}
            onClose={() => setAnchorPopover(null)}
            open={Boolean(popoverAnchor)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            <Grid container justifyContent='space-between'
                classes={{ root: classes.popoverContainer }}
                direction='column'>
                <Grid item>
                    <Typography
                        style={{ fontWeight: "bold" }}>
                        {buttonLabel}
                    </Typography>
                </Grid>
                <Grid item>
                    <FilterSelectItem
                        buttonLabel={buttonLabel}
                        popowerTitle={buttonLabel}
                        itemId={`filter_${popoverId}`}
                        open={Boolean(formAnchor)}
                        onOpen={(event) => setFormAnchor(event.currentTarget)}
                        onClose={(event) => setFormAnchor(null)}
                        selectOptions={itemsList}
                        selectionChange={onValueChanged}
                        selectedItem={selectedItems} />
                </Grid>
                <Grid item container justifyContent="space-between" direction='row'>
                    <Grid item>
                        <Button onClick={onPopoverFormClear}>Effacer</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={onPopoverFormValidate}
                            style={{ color: theme.palette.secondary.main }}>
                            valider
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Popover>
    </Grid>
}