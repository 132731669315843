import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/styles";
import React from 'react';


const useStyles = makeStyles(theme => ({
    popoverContainer: {
       // width: "25rem"
    }
}))

export const FilterSelectItem = ({
    buttonLabel, popowerTitle, itemId, selectOptions,
    selectionChange, selectedItem }) => {

    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const labelFromValue = (value) => {
        if(value==='') return ""
        return selectOptions.filter(item => item.value===value).shift().label
    }

    return (
        <Grid item>
            <Grid container justifyContent='space-between'
                classes={{ root: classes.popoverContainer }}
                direction='column'>
                <FormControl sx={{ m: 1 }}>
                    <InputLabel id={`${itemId}Anchor`}> {popowerTitle}</InputLabel>
                    <Select
                        labelId={`${itemId}Anchor`}
                        id={itemId}
                        multiple
                        value={selectedItem}
                        onChange={selectionChange}
                        input={<OutlinedInput id={`${itemId}Chip`} label={buttonLabel} />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (<Chip key={value} label={labelFromValue(value)} />))}
                            </Box>
                        )}
                        MenuProps={MenuProps}>
                        {selectOptions.map((optionItem) => (
                            <MenuItem key={optionItem.value} value={optionItem.value}>
                                <Checkbox checked={selectedItem.indexOf(optionItem.value) > -1} />
                                <ListItemText primary={optionItem.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </Grid>
        </Grid>
    );
};
