import React, { useState } from "react"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import Card from "@material-ui/core/Card"
import CardMedia from '@material-ui/core/CardMedia'
import { Link } from 'gatsby'
import { Button } from "@material-ui/core"

// import LocationOnOutlined from "@material-ui/icons/LocationOnOutlined"



const useStyles = makeStyles(theme => ({
    frameContainer: {
        width: "20rem",
        "&:hover": {
            cursor: "pointer",
        },
        [theme.breakpoints.down("sm")]: {
            width: '20rem',
        },
        [theme.breakpoints.down("xs")]: {
            width: '20rem',
        },
    },
    annoncesContainer: {
        padding: '5rem 0',
        width: '100%',
        height: '50rem',
        [theme.breakpoints.down("md")]: {
            height: '40rem',
        },
        [theme.breakpoints.down("xs")]: {
            width: "20rem",
        },
    },
    imageContainer: {
        marginBottom: "3rem",
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '20rem',
        height: '13rem',
        position: 'relative',
        [theme.breakpoints.down("md")]: {
            width: '20rem',
        },
        [theme.breakpoints.down("xs")]: {
            width: "12rem",
        },
    },

    SellerText: {
        padding: '0.25rem',
        backgroundColor: theme.palette.secondary.main,
        fontSize: '1rem',
        position: 'absolute',
        right: 0,
        bottom: '0.5rem',
        textAlign: 'right',
        width: "10rem",
        height: "2rem"
    },
    desContainer: {
        width: '20rem',
        [theme.breakpoints.down("xs")]: {
            width: '10rem',
        },
    },

    cardMedia: {
        height: '12rem',
        width: '20rem',
        [theme.breakpoints.down("md")]: {
            height: '12rem',
            width: '20rem',
        },
        [theme.breakpoints.down("xs")]: {
            height: '12rem',
            width: '20rem',
        },
    },
    item: {
        height: "30rem"
    }

}))


export default function AnnonceFrameGrid({
    annonce,
    disableQuickView
}) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const matchesMD = useMediaQuery(theme => theme.breakpoints.down("md"))
    const matchesSM = useMediaQuery(theme => theme.breakpoints.down("sm"))

    if (matchesMD && open) {
        setOpen(false)
    }

    // const imageIndex = colorIndex(product, variant, selectedColor)
    /*
      const imgURL =
        imageIndex !== -1
          ? product.node.variants[imageIndex].images[0].localFile
          : variant.images[0].localFile
    
      const image = getImage(imgURL)
    
      const productName = product.node.name.split(" ")[0]
    */

    return (
        <Grid
            item
            classes={{
                root: clsx(classes.frameContainer, {
                    [classes.invisibility]: open === true,
                }),
            }}
        >
            <Card component={Button} onClick={()=>{navigate(`../maison/${annonce.id.toLowerCase()}`)}} elevation={3} style={{ paddingBottom: "1rem", backgroundColor: theme.palette.common.white }}>
                <Grid
                    classes={{ root: classes.item }}
                    container
                    direction="column" alignItems="center" justifyContent="space-between"
                    onClick={() => matchesSM || disableQuickView ? navigate(`/${annonce.title}`) : setOpen(true)}
                >
                    <Grid item classes={{ root: classes.imageContainer }}>
                        <CardMedia component='img' image={annonce.img}
                            classes={{ root: classes.cardMedia }} />
                    </Grid>
                    <Grid item >
                        <Typography variant='h1'
                            style={{ marginTop: "1rem", color: theme.palette.common.black }}>
                            {annonce.title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography classes={{ root: classes.desContainer }}
                            variant='body1'
                            style={{ textAlign: "center", color: theme.palette.common.black, wordWrap: "break-word" }}>
                            {annonce.desc}</Typography>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" direction="row">
                        <Grid item>
                            {/* <LocationOnOutlined /> */}
                        </Grid>
                        <Grid item>
                            <Typography classes={{ root: classes.desContainer }}
                                variant='body1'
                                style={{ textAlign: "center", color: theme.palette.common.black }}>{annonce.city}
                                {annonce.zipCode}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Typography
                            style={{ color: theme.palette.secondary.main }}>{annonce.price} €
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component={Link} to={`../maison/${annonce.id.toLowerCase()}`}>
                            Plus de details
                        </Typography>
                    </Grid>

                </Grid>
            </Card>
        </Grid >
    )
}

