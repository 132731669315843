import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Typography from "@material-ui/core/Typography"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from "@material-ui/styles"
import React, { useState } from 'react'
import theme from "../../ui/theme"
import Validate from "../../ui/validate.js"

const useStyles = makeStyles(theme => ({

    resultButton: {
        position: "absolute",
        bottom: 0,
        left: "40%",
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        },
        [theme.breakpoints.down("xs")]: {
            left: "2%",
            bottom: "4rem",
            width: "22rem",
            border: `2px solid ${theme.palette.secondary.main}`,
        }
    },
    filterCard: {
        padding: "2rem 0rem 3rem 0rem",
        width: "100%",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            height: "19rem",
            padding: "1rem"
        }
    },
    formsRow: {
        width: '100%'
    },

    filterButton: {
        borderRadius: "5rem",
        border: `2px solid ${theme.palette.common.clair}`,
        marginTop: "1rem"
    },
    prixPopoverContainer: {
        width: "38rem",
        height: "13rem",
        padding: "1rem 2rem",
        [theme.breakpoints.down("xs")]: {
            height: "16rem"
        }
    },
    popoverTextField: {
        width: "15rem"
    },
    popoverPriceRow: {
        width: "34rem",
        [theme.breakpoints.down('xs')]: {
            width: "40rem",
            height: "8rem"
        }
    },
    pricePopoverButtonRow: {
        width: "32rem",
        [theme.breakpoints.down('xs')]: {
            width: "22rem"
        }
    },
    alerteButton: {
        position: "absolute",
        right: "0.5rem",
        bottom: "0.5rem",
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        },
        [theme.breakpoints.down("xs")]: {
            width: "22rem",
            left: "2%",
            border: `2px solid ${theme.palette.secondary.main}`,
        },
        [theme.breakpoints.only("md")]: {
            marginTop: "1rem",
            border: "none"
        },
        [theme.breakpoints.only("sm")]: {
            bottom: "3rem"
        }
    }
}))


export const ItemSelectionMinMaxForm = (
    { buttonLabel, popoverId, initialItemValue, minimumHelperText, maximumHelperText, minimumPlaceHolder, maximumPlaceHolder, inputProps,
        onClear, onValidate }) => {

    const classes = useStyles()
    const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'))

    const [popoverAnchor, setPopoverAnchor] = useState(null)
    const [itemValue, setItemValue] = useState(initialItemValue)

    //const [itemValue, setSelectedItems] = useState({})
    const [validationErrors, setValidationErrors] = useState({})

    const onMinimumChanged = (e) => {
        setItemValue({ ...itemValue, minimum: e.target.value })
        const valid = Validate({ montant: itemValue.minimum })
        setValidationErrors({ minimum: !(valid.montant) })
    }
    const onMaximumChanged = (e) => {
        setItemValue({ ...itemValue, maximum: e.target.value })
        const valid = Validate({ montant: itemValue.maximum })
        setValidationErrors({ maximum: !(valid.montant) })
    }

    const onOpenPopower = (e) => {
        setItemValue(initialItemValue)
        setPopoverAnchor(e.currentTarget)
    }

    const onPopoverFormClear = (e) => {
        onClear()
        setPopoverAnchor(null)
    }

    const onPopoverFormValidate = (e) => {
        onValidate(itemValue)
        setPopoverAnchor(null)
    }

    return (
        <Grid item key={popoverId} >
            <Button style={{ border: `2px solid ${itemValue.minimum>0 ? theme.palette.secondary.main : 'silver'}` }}
                aria-describedby={popoverId} onClick={onOpenPopower}
                classes={{ root: classes.filterButton }}>
                {buttonLabel}
            </Button>
            <Popover
                id={popoverId}
                anchorEl={popoverAnchor} // 1
                onClose={() => setPopoverAnchor(null)} // 2
                open={Boolean(popoverAnchor)} // 3
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <Grid container
                    justifyContent='space-between'
                    classes={{ root: classes.prixPopoverContainer }}
                    direction='column'>
                    <Grid item>
                        <Typography
                            style={{ fontWeight: "bold" }}>
                            {buttonLabel}
                        </Typography>
                    </Grid>
                    <Grid item container
                        justifyContent='space-between'
                        classes={{ root: classes.popoverPriceRow }}
                        direction={matchesXS ? "column" : 'row'}>
                        <Grid item>
                            <TextField
                                classes={{ root: classes.popoverTextField }}
                                value={itemValue.minimum} // 4
                                onChange={onMinimumChanged}
                               // onBlur={option.onBlur}
                                error={validationErrors.minimum}
                                helperText={validationErrors.minimum && minimumHelperText}
                                InputProps={inputProps}
                                placeholder={minimumPlaceHolder}
                                variant="outlined" />
                        </Grid>
                        <Grid item>
                            <TextField
                                classes={{ root: classes.popoverTextField }}
                                value={itemValue.maximum}
                                onChange={onMaximumChanged}
                               // onBlur={option.maxOnBlur}
                                error={validationErrors.maximum}
                                helperText={validationErrors.maximum && maximumHelperText}
                                InputProps={inputProps}
                                placeholder={maximumPlaceHolder}
                                variant="outlined" />
                        </Grid>
                    </Grid>
                    <Grid item container
                        classes={{ root: classes.pricePopoverButtonRow }}
                        justifyContent='space-between' direction='row'>
                        <Grid item>
                            <Button
                               // disabled={!option.valide}
                                onClick={onPopoverFormClear}>Effacer</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onPopoverFormValidate}
                               // disabled={option.disabled}
                                style={{ color: theme.palette.secondary.main }}>
                                Valider
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
    )
}