export default function validate(values){


    const validators = {
        email: val => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val),
        phone: val => val =>
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(val),
        montant: val => val > 0
    }

    const valid = {}

    Object.keys(values).map(field => {
        valid[field] = validators[field](values[field])
    })

    return valid
}