import { Button } from '@material-ui/core'
import Link from 'gatsby'
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Pagination from "@material-ui/lab/Pagination"
import PaginationItem from "@material-ui/lab/PaginationItem"
import { makeStyles, styled, useTheme } from '@material-ui/styles'
import clsx from "clsx"
import React from 'react'
import GridIcon from "../../images/Grid"
import ListIcon from "../../images/List"
import AnnonceFrameGrid from './annonce-frame-grid'
import AnnonceFrameList from './annonce-frame-list'



const useStyles = makeStyles(theme => ({
    pagination: {
        alignSelf: "flex-end",
        marginRight: "2%",
        marginTop: "-2rem",
        marginBottom: "4rem",
        [theme.breakpoints.only("md")]: {
            marginTop: "1rem",
        },
    },
    annonceContainer: {
        width: "100%",
        [theme.breakpoints.only("xl")]: {
            "& > *": {
                marginRight: ({ layout }) =>
                    layout === "grid" ? "calc((100% - (20rem * 5)) / 4)" : 0,
                marginBottom: "3rem",
            },
            "& > :nth-child(5n)": {
                marginRight: 0,
            },
        },
        [theme.breakpoints.only("lg")]: {
            "& > *": {
                marginRight: ({ layout }) =>
                    layout === "grid" ? "calc((100% - (20rem * 4)) / 3)" : 0,
                marginBottom: "3rem",
            },
            "& > :nth-child(4n)": {
                marginRight: 0,
            },
        },
        [theme.breakpoints.only("md")]: {
            "& > *": {
                marginRight: ({ layout }) =>
                    layout === "grid" ? "calc((100% - (20rem * 3))/2)" : 0,
                marginBottom: "3rem",
            },
            "& > :nth-child(3n)": {
                marginRight: 0,
            },
        },
        [theme.breakpoints.only("sm")]: {
            "& > *": {
                marginRight: ({ layout }) =>
                    layout === "grid" ? "calc(100% - (25rem * 2))" : 0,
                marginBottom: "3rem",
            },
            "& > :nth-child(n)": {
                marginRight: 0,
            },
            width: '100%'
        },
    },

    button: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRightColor: `${theme.palette.primary.main} !important`,
        borderRadius: 25,
        backgroundColor: "#fff",
        padding: "0.5rem 1.5rem",
        "&:hover": {
            backgroundColor: "#fff",
        },
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        },
    },
    buttonGroup: {
        right: 0,
        marginTop: "1.5rem",
        //marginRight: "3rem",
        [theme.breakpoints.down("md")]: {
            position: "relative",
            display: "flex",
            alignSelf: "flex-end",
            marginRight: 0,
            marginBottom: 0,
            marginTop: "3rem",
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: "1.5rem",
        },
        [theme.breakpoints.down("xs")]: {
            marginRight: "1rem",
        },
    },
    annonceButton: {
        width: '100%'
    }
}))

export const StyledPagination = props => {
    const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
        fontFamily: "Montserrat",
        fontSize: "1rem",
        color: theme.palette.primary.main,
        "&.Mui-selected": {
            color: "#fff",
        },
    }))

    return (
        <Pagination
            {...props}
            renderItem={item => <StyledPaginationItem {...item} />}
        />
    )
}


const ListOfAnnonces = ({ annonces, content, layout, setPage, setLayout, page, annoncesPerPage }) => {
    const classes = useStyles({ layout })
    const matchesSM = useMediaQuery(theme => theme.breakpoints.down("sm"))

    const theme = useTheme()
    const FrameHelper = ({ Frame, annonce }) => {
        return (
            <Frame
                annonce={annonce}
                disableQuickView={false} />
        )
    }
    const changeLayout = option => {
        setLayout(option)
        setPage(1)
    }

    return (
        <Grid container alignItems='flex-end' spacing={2} direction='column'>
            <Grid item classes={{ root: classes.buttonGroup }} >
                <ButtonGroup>
                    <Button
                        onClick={() => changeLayout("list")}
                        classes={{
                            outlined: clsx(classes.button, {
                                [classes.selected]: layout === "list",
                            }),
                        }}>
                        <ListIcon color={layout === "list" ? "#fff" : theme.palette.primary.main} />
                    </Button>
                    <Button
                        onClick={() => changeLayout("grid")}
                        classes={{
                            outlined: clsx(classes.button, {
                                [classes.selected]: layout === "grid",
                            }),
                        }}>
                        <GridIcon color={layout === "grid" ? "#fff" : theme.palette.primary.main} />
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid
                item
                container
                direction={matchesSM ? "column" : "row"}
                alignItems={matchesSM ? "center" : undefined}
                classes={{ root: classes.annonceContainer }}>
                {content
                    .slice((page - 1) * annoncesPerPage, page * annoncesPerPage)
                    .map((item, i) => (
                        // <Button style={{width: layout === "grid" ? "20rem" : "auto", }} component={Link} to={`../maison/${content[i].id.toLowerCase()}`} classes={{root: layout != "grid" && classes.annonceButton}} key={item.id}>
                            <FrameHelper
                                Frame={layout === "grid" ? AnnonceFrameGrid : AnnonceFrameList}
                                key={item.id}
                                annonce={item}
                            />
                        // </Button>
                    ))}
            </Grid>

        </Grid>

    )
}
export default ListOfAnnonces